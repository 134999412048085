<template>
<div>
    <p class="pageTopic">เลือกช่องทางการชำระเงิน</p>
    <b-row align-h="around" class="text-center" cols="2" cols-sm="2" cols-md="3" cols-lg="3">
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('credit')">
                <span id="creditPaymentText">บัตรเครดิต</span>
                <a-tag class="promotion-padding">
                    &nbsp; &nbsp;
                </a-tag>
                <div><img :src="require(`@/assets/${imgCredit}.png`)" id="imgCredit" width="28px" /></div>
            </div>
        </b-col>
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('bank')">
                <span id="bankPaymentText">ธนาคาร</span>
                <a-tag class="promotion-padding">
                    -
                </a-tag>
                <div><img :src="require(`@/assets/${imgBank}.png`)" id="imgBank" width="28px" /></div>
            </div>
        </b-col>
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('linepay')">
                <span id="linepayPaymentText">Line Pay</span>
                <a-tag class="promotion-padding">
                    -
                </a-tag>
                <div><img :src="require(`@/assets/${imgLinepay}.png`)" id="imgLinepay" width="20px" /></div>
                <div style="margin-top: -7px;margin-bottom: -13px;"><img :src="require(`@/assets/${imgPay}.png`)" id="imgPay" width="22px" /></div>
            </div>
        </b-col>
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('truewallet')">
                <span id="truewalletPaymentText">True Wallet</span>
                <a-tag class="promotion-padding">
                    -
                </a-tag>
                <div><img :src="require(`@/assets/${imgTruewallet}.png`)" id="imgTruewallet" width="28px" /></div>
            </div>
        </b-col>
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('qrcode')">
                <span id="qrcodePaymentText">QR Code</span>
                <a-tag class="promotion-padding">
                    -
                </a-tag>
                <div><img :src="require(`@/assets/${imgQrcode}.png`)" id="imgQrcode" width="28px" /></div>
            </div>
        </b-col>
        <b-col class="channel">
            <div class="paymentChannel" @click="SET_IS_SELECTED(true);changePayment('shopee')">
                <span id="shopeePaymentText">ShopeePay</span>
                <a-tag class="promotion-padding">
                    -
                </a-tag>
                <div><img :src="require(`@/assets/${imgShopee}.png`)" id="imgShopee" width="28px" /></div>
            </div>
        </b-col>
        <!-- <b-col class="channel">
            <div class="paymentChannel true" @click="SET_IS_SELECTED(true);changePayment('truemoney')">
                <span id="truemoneyPaymentText">True Money</span>
                <div><img :src="require(`@/assets/${imgTruemoney}.png`)" id="imgTruemoney" width="28px" /></div>
            </div>
        </b-col> -->
    </b-row>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    name: "Homepage",
    mounted() {
        //document.title = 'คู่มือการใช้งาน'
        this.SET_IS_SELECTED(false)
        this.checkMode()
    },
    computed: {
        ...mapGetters(['GET_MODE','GET_IS_SELECTED'])
    },
    watch: {
        GET_MODE() {
            this.checkMode()
        }
    },
    data() {
        return {
            imgCredit: "credit-card",
            imgBank: "bank",
            imgLinepay: "linepay",
            imgPay: "pay",
            imgTruewallet: "wallet",
            imgQrcode: "qrcode",
            imgTruemoney: "wallet",
            imgShopee: "shopeepay",
            channel: ["credit", "bank", "linepay", "truewallet", "qrcode", "shopee"] // "truemoney"
        }
    },
    methods: {
        ...mapActions(['SET_IS_SELECTED', 'SET_PAYMENT_CHANNEL']),
        changePayment(channel) {
            this.imgCredit = "credit-card"
            this.imgBank = "bank"
            this.imgLinepay = "linepay"
            this.imgPay = "pay"
            this.imgTruewallet = "wallet"
            this.imgQrcode = "qrcode"
            this.imgShopee = "shopeepay"
            this.imgTruemoney = "wallet"

            this.channel.forEach(element => {
                document.getElementById(element + "PaymentText").className = ""
            });
            this.SET_PAYMENT_CHANNEL(channel)
            if (channel == "credit") {
                this.imgCredit = this.imgCredit + "1"
            } else if (channel == "bank") {
                this.imgBank = this.imgBank + "1"
            } else if (channel == "linepay") {
                this.imgLinepay = this.imgLinepay + "1"
                this.imgPay = this.imgPay + "1"
            } else if (channel == "truewallet") {
                this.imgTruewallet = this.imgTruewallet + "1"
            } else if (channel == "qrcode") {
                this.imgQrcode = this.imgQrcode + "1"
            } else if (channel == "truemoney") {
                this.imgTruemoney = this.imgTruemoney + "1"
            } else if (channel == "shopee") {
                this.imgShopee = this.imgShopee + "1"
            }
            document.getElementById(channel + "PaymentText").className = "paymentChannelActive"
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('paymentChannel').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('paymentChannel').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
}
</script>

<style scoped>
.pageTopic {
    font-size: 20px;
    font-weight: bold;
}

.paymentChannel {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 8%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.paymentChannel:hover {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.paymentChannelActive {
    color: #EE806B;
}

.allChanel,
.channel {
    padding: 0px;
}

@media screen and (max-width: 500px) {
    .pageTopic {
        font-size: 14px;
        text-align: center;
    }

    .paymentChannel {
        font-size: 14px;
        width: 85%;
        margin-bottom: 1rem;
    }

    .true {
        margin-top: 10%;
        margin-left: 57%;
    }
    
    #imgCredit,#imgBank,#imgTruewallet,#imgQrcode{
        width: 22px;
    }
    #imgLinepay{
        width: 16px;
    }
    #imgPay{
        width: 14px;
    }
}

.darkmode {
    background-color: #35363A;
}

.darkmode:hover {
    box-shadow: 0px 3px 7px rgba(255, 255, 255, 0.904);
}

.shopee-promotion-tag{
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin: .5rem;
}
.promotion-padding{
    display: block;
    margin: .5rem;
    visibility: hidden;
}
</style>
