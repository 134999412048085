<template>
<div class="scope">
    <div id="paymentSystem" class="paymentSystem">
        <div class="mb-2"><b>ระบบเติมทอง</b><br></div>
        <div v-html="paymentSystem"></div>
    </div>
    <PaymentBtn />
    <b-modal v-model="isShowModal" :hide-footer="true" :title="'ขั้นตอนการเติมผ่านช่องทาง'+guides.credit.name" @show="SET_IS_SELECTED(true)" @hide="SET_IS_SELECTED(false)">
        <template v-slot:modal-header>
            <span class="channelGuideTopic">ขั้นตอนการเติมผ่านช่องทาง{{guideInfo['name']}}</span>
            <b-icon-collection-play-fill class="mr-5 h4" style="color:red"></b-icon-collection-play-fill>
        </template>
        <div v-html="guideInfo['step']" class="channelGuideDetail"></div>
    </b-modal>
</div>
</template>

<script>
import PaymentBtn from './../elements/addcoin/PaymentBtn.vue'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    components: {
        PaymentBtn
    },
    mounted() {
        //document.title = 'วิธีเติมทอง';
        this.checkMode()
    },
    computed: {
        ...mapGetters({
            isSelected: 'GET_IS_SELECTED',
            paymentChannel: 'GET_PAYMENT_CHANNEL',
            mode: 'GET_MODE'
        })
    },
    watch: {
        isSelected: function () {
            this.isShowModal = this.isSelected
            if (this.isShowModal) {
                this.guideInfo = this.guides[this.paymentChannel]
            }
        },
        mode() {
            this.checkMode()
        }
    },
    data() {
        return {
            isShowModal: false,
            guideInfo: {},
            paymentSystem: `1. ผลิตภัณฑ์สินค้าบนเว็บไซต์ www.novelrealm.com มีสิ่งแทนการแลกเปลี่ยนหลายรูปแบบซึ่งเป็นไปตามที่ ห้างหุ้นส่วนสามัญโนเวลเลียว์มได้กำหนดไว้ การเติมเงินและลักษณะการใช้งานของแต่ละผลิตภัณฑ์ อาจจะมีการใช้งานที่แตกต่างกันขึ้นอยู่กับเงื่อนไขที่ทางห้างหุ้นส่วนสามัญโนเวลเลียว์มได้กำหนด<br><br>
                2. ผู้ใช้เข้าใจและรับทราบว่าทางเราไม่มีบริการรับแลกเปลี่ยนซื้อขายสิ่งแทนการแลกเปลี่ยนให้ผู้ใช้ การซื้อขายสิ่งแทนการแลกเปลี่ยนระหว่างผู้ใช้ถือว่าเป็นการละเมิดข้อตกลงนี้ ทางเรามีสิทธิโดยไม่ต้องแจ้งให้ทราบล่วงหน้า ในการใช้มาตรการที่เหมาะสม และตรวจสอบให้แน่ใจว่าทางเราไม่มีแพลตฟอร์มบริการรับแลกเปลี่ยนซื้อขายสิ่งแทนการแลกเปลี่ยน<br><br>
                3. ผู้ใช้เข้าใจและยอมรับ ทางเราจะไม่ทำการเปลี่ยนแปลงหรือแก้ไขบริการ หากผู้ใช้เติมเงินสำเร็จเรียบร้อย และจะไม่สามารถเรียกขอคืนเงินสำหรับการเติมเงินเข้าสู่บัญชีผู้ใช้ในทุกรูปแบบไม่ว่ากรณีใดๆก็ตาม <br><br>
                4. เมื่อผู้ใช้เติมเงินผ่านช่องทางบนเว็บไซต์ www.novelrealm.com แล้ว ผู้ใช้จะต้องตรวจสอบและยืนยันข้อมูลในบัญชีของท่าน ความผิดพลาดจากการดำเนินการ ความไม่เข้าใจหรือเข้าใจวิธีการเติมเงินไม่ดีพอ เป็นเหตุให้บัญชีผิดพลาด หรือเลือกเติมเงินผิดประเภทจนเสียผลประโยชน์ตนเอง ผู้ใช้ไม่สามารถเรียกร้องค่าเสียหายหรือชดเชยจากทางเราได้<br><br>
                5. หากผู้ใช้เลือกใช้บริการเติมเงินจากบุคคลที่สาม ผู้ใช้จะต้องเคารพในหลักการและข้อตกลงทุกข้อของบุคคลที่สาม โดยผู้ใช้จะต้องรักษาข้อมูลส่วนตัวให้ดี รวมไปถึงแต่ไม่จำกัดถึงหมายเลขบัญชีธนาคาร รหัสผ่าน หมายเลขยืนยันตัวตนเป็นต้น ทางเราจะไม่รับผิดชอบต่อข้อพิพาทที่เกิดขึ้นกับบริการการจ่ายเงินของผู้ใช้กับบุคคลที่สาม<br><br>
                6. หากผู้ใช้ละเมิดข้อตกลงเรื่องการชำระเงินของหนังสือฉบับนี้ เป็นเหตุให้บัญชีถูกระงับและ/หรือผู้ใช้ถูกยุติการให้บริการ จะไม่สามารถเรียกร้องค่าเสียหาย ค่าชดเชยจากทางเราได้<br><br>
                7. หากเกิดกรณีระบบเติมเงินของทางเราผิดพลาดเป็นเหตุให้การเติมเงินของผู้ใช้ล้มเหลว และเมื่อข้อมูลได้รับการกู้คืนมา และยังมีข้อมูลที่ใช้ได้ตรงกับหลักฐานตามกฎหมายที่ผู้ใช้นำเสนอมา ทางเรายินดีจะเปลี่ยนแปลงข้อมูลและมีมาตรการแก้ไขให้ตามหลักฐานการเติมเงินของผู้ใช้<br><br>
                8. หากเป็นเพราะระบบเติมเงินของทางเราผิดพลาด เป็นเหตุให้ยอดการเติมเงินน้อยกว่าจำนวนเงินที่เติมไปจริง ทางเรายินดีจะชดเชยส่วนต่าง<br><br>
                9. หากเป็นเพราะระบบเติมเงินของทางเราผิดพลาด เป็นเหตุให้ยอดการเติมเงินมากกว่าจำนวนเงินที่เติมไปจริง ทางเรามีสิทธิจะเรียกส่วนต่างกลับคืน<br><br>
                10. ผู้ใช้เข้าใจและยินยอมว่าเมื่อผู้ใช้งานใช้สิ่งแทนการแลกเปลี่ยนในบัญชีของผู้ใช้สั่งจองผลิตภัณฑ์สำเร็จเรียบร้อยแล้ว จะไม่สามารถเปลี่ยนแปลง แก้ไขคำสั่งซื้อขายได้อีก นอกจากจะได้รับการยินยอมจากทางเรา สิ่งแทนการแลกเปลี่ยนที่ผู้ใช้ได้ใช้เพื่อให้ได้มาซึ่งค่าบริการการอ่านรวมถึงซื้อหนังสือแล้ว จะไม่สามารถขอคืนได้`,
            guides: {
                credit: {
                    name: 'บัตรเครดิต',
                    step: `
                        1. เลือกช่องทางการชำระเงินผ่าน<b>บัตรเครดิต<br><br>
                        2. เลือกจำนวนทองที่ต้องการเติม<br><br>
                        3. กด “ต่อไป” เพื่อดำเนินการชำระเงิน<br><br>
                        4. กรอกข้อมูลบัตรเครดิต ได้แก่ เลขหน้าบัตร ชื่อเจ้าของบัตร วันเดือนปีหมดอายุบัตร และเลข 3 ตัวหลังบัตร <br><br>
                        5. เมื่อกรอกเสร็จให้กด “Pay”<br><br>
                        6.เสร็จสิ้นขั้นตอน 
                    `
                },
                bank: {
                    name: 'ธนาคาร',
                    step: `
                        1. เลือกช่องทางการชำระเงินผ่านธนาคาร<br><br>
                        2. เลือกจำนวนทองที่ต้องการเติม<br><br>
                        3. โอนเงินเข้าบัญชีโนเวลเลียว์มตามจำนวนเงินที่ต้องชำระ<br>
                        (หมายเหตุ: กรุณาตรวจสอบข้อมูลการโอนให้ถี่ถ้วนก่อน หากโอนผิดจะต้องแสดงหลักฐานแก่เจ้าหน้าที่ และดำเนินการตามขั้นตอนต่างๆ เพื่อป้องกันมิจฉาชีพ<br><br>
                        4. อัพโหลดหลักฐานการโอน และกรอกรายละเอียด ได้แก่ ชื่อ-สกุล และเลือกวันที่และเวลาโอน <br><br>
                        5. กด “ยืนยันการชำระเงิน”<br><br>
                        6.เสร็จสิ้นขั้นตอน `
                },
                linepay: {
                    name: 'Line Pay',
                    step: `
                        1. เลือกช่องทางการชำระเงินผ่าน Line Pay<br><br>
                        2. เลือกจำนวนทองที่ต้องการเติม<br><br>
                        3. กด “ต่อไป” เพื่อดำเนินการชำระเงิน<br><br>
                        4. เข้าสู่ระบบ และเปิด Line บนมือถือ เลือกเมนู Wallet แล้วแสกน QRcode<br>
                        (หมายเหตุ: ต้องมีเงินอยู่ในบัญชี Line Pay) <br><br>
                        5. กด “ชำระเงิน”<br><br>`
                },
                truewallet: {
                    name: 'True Wallet',
                    step: `
                        1. เลือกช่องทางการชำระเงินผ่าน True Wallet<br><br>
                        2. เลือกจำนวนทองที่ต้องการเติม แล้วโอนเงินเข้าสู่เบอร์ True Wallet <br><br>
                        3. กรอกเบอร์โทร True Wallet<br><br>
                        4. กด “ต่อไป”<br><br>
                        5. กรอกเลข OTP<br><br>
                        6. กด “Verify”<br><br>
                        7. เสร็จสิ้นขั้นตอน `
                },
                qrcode: {
                    name: 'QR Code',
                    step: `
                    1. เลือกช่องทางการชำระเงินผ่าน QR Code<br><br>
                    2. แสกน QR Code<br><br>`
                },
                truemoney: {
                    name: 'True Money',
                    step: `
                    1. เลือกช่องทางการชำระเงินผ่าน True Money<br><br>
                    2. กรอกรหัสบัตร Truemoney<br><br>
                    3. กด “ต่อไป”<br><br>
                    4. เสร็จสิ้นขั้นตอน `
                }
            },
        }
    },
    methods: {
        ...mapActions(['SET_IS_SELECTED']),
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('paymentSystem').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('paymentSystem').classList.add('darkmode')
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 3% 10% 5% 10%;
}

.paymentSystem {
    background-color: #ffffff;
    height: 51vh;
    overflow: scroll;
    border: 20px solid #ffffff;
    margin-bottom: 3vh;
}

.modal-header {
    padding-bottom: 3px;
}

.channelGuideTopic {
    font-size: 17px;
}

@media screen and (max-width: 500px) {
    .scope {
        padding: 2% 2% 2% 2%;
    }

    .paymentSystem {
        border: 10px solid #ffffff;
        font-size: 14px;
    }

    .channelGuideTopic {
        font-size: 14px;
    }

    .channelGuideDetail {
        font-size: 14px;
    }
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 3% 20% 5% 20%;
    }
}

.darkmode {
    background-color: #35363A;
    border: 20px solid #35363A;
}
</style>
